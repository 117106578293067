/* Change the background color of the page */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #f3c8f7; /* Light blue background */
}

/* Style the heading */
h1 {
  color: rgb(250, 59, 209);
}

/* Style the button */
button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold; /* Make the button text bold */
  cursor: pointer;
  border: none;
  background-color: rgb(250, 59, 209); /* Green background */
  color: rgb(255, 255, 255); /* White text */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

/* Button hover effect */
button:hover {
  background-color: rgb(253, 97, 219); /* Darker green on hover */
}
